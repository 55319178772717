.home-humility {
  text-align: center;
}

.home-fa-twitter {
  margin-left: 2em;
}

h5 {
  margin-left: 0.5rem !important;
}
